import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';

import './Loading.scss';

const index = () => {
  return (
    <div className="loading">
      <Stack spacing={2} direction="row">
        <CircularProgress color="success" size={55} />
      </Stack>
    </div>
  );
};

export default index;
