import axios from '../axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchCafes = createAsyncThunk('cafes/fetchCafes', async () => {
  try {
    const { data } = await axios.get('/cafes');
    return data;
  } catch (error) {
    console.log(error);
  }
});

const cafesSlice = createSlice({
  name: 'cafes',
  initialState: {
    cafes: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCafes.pending, (s) => {
      s.status = 'loading';
    });
    builder.addCase(fetchCafes.fulfilled, (s, action) => {
      if (!action.payload) {
        s.status = 'failed';
        s.cafes = [];
      } else {
        s.status = 'succeeded';
        s.cafes = action.payload;
      }
    });
    builder.addCase(fetchCafes.rejected, (s) => {
      s.status = 'failed';
    });
  },
});

export default cafesSlice.reducer;
