import React from 'react';
import { LuMinus, LuPlus } from 'react-icons/lu';
import { useDispatch } from 'react-redux';

import './ButtonInCart.scss';
import { addItem, removeItem } from '../../../redux/cartSlice';

const ButtonInCart = ({ openModal, itemCount, item }) => {
  const dispatch = useDispatch();

  const handleAddItem = () => {
    if (item.sizes) {
      openModal();
      return;
    }
    dispatch(addItem({ item, quantity: 1 }));
  };

  const handleRemoveItem = () => {
    dispatch(removeItem({ item }));
  };

  return (
    <div className="menu-item__button menu-item__button_in_cart">
      <button className="button-in-cart__button" onClick={handleRemoveItem}>
        <LuMinus />
      </button>
      <div className="button-in-cart__count">{itemCount}</div>
      <button className="button-in-cart__button" onClick={handleAddItem}>
        <LuPlus />
      </button>
    </div>
  );
};

export default ButtonInCart;
