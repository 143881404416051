import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pribors: '',
  phone: '',
  address: '',
  comment: '',
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setComment: (state, action) => {
      state.comment = action.payload;
    },
  },
});

export const { setPhone, setAddress, setComment } = orderSlice.actions;

export default orderSlice.reducer;
