import { useEffect } from 'react';
import './Sizes.scss';

const Sizes = ({ item, setLocalItem, selectedSize, setSelectedSize }) => {
  useEffect(() => {
    if (selectedSize !== null) {
      const updatedSizes = item.sizes.map((size) => ({
        ...size,
        is_default: size.id === selectedSize,
      }));

      const activeSize = updatedSizes.find((size) => size.id === selectedSize);

      setLocalItem((prevItem) => ({
        ...prevItem,
        sizes: updatedSizes,
        price: activeSize?.price || prevItem.price,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSize]);

  const handleSizeChange = (sizeId) => {
    setSelectedSize(sizeId);
  };

  if (item.sizes) {
    return (
      <div className="sizes">
        {item.sizes.map((size) => (
          <div
            key={size.id}
            className={`size ${selectedSize === size.id ? 'selected' : ''}`}
            onClick={() => handleSizeChange(size.id)}
          >
            <p className="name">{size.size_name}</p>
            <p className="price">
              {size.price} <span className="rubl">₽</span>
            </p>
          </div>
        ))}
      </div>
    );
  }
};

export default Sizes;
