import React from 'react';
import './Loading.scss';

const LoadingError = ({ errorMessage }) => {
  const handleReload = () => {
    window.location.reload();
  };

  console.log(errorMessage);

  return (
    <div className="loading-error">
      <div className="error-message">Приносим свои извинения</div>
      <div className="error-details">Ведутся технические работы</div>
      <button className="reload-button" onClick={handleReload}>
        Попробовать снова
      </button>
    </div>
  );
};

export default LoadingError;
