import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  cafeName: '',
  totalPrice: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      const { item, quantity } = action.payload;
      const existingItem = state.items.find(
        (i) => i.id === item.id && i.price === item.price
      );

      if (existingItem) {
        if (existingItem.quantity >= 20) {
          return;
        }
        existingItem.quantity += quantity;
        state.totalPrice += item.price * quantity;
      } else {
        if (quantity > 20) {
          existingItem.quantity = 20;
          state.items.push({ ...item, quantity: 20 });
          state.totalPrice += item.price * 20;
        } else {
          if (item.selectedSize) {
            const sizeName = item.selectedSize.size_name;
            delete item.selectedSize;
            state.items.push({ ...item, quantity, sizeName });
            state.totalPrice += item.price * quantity;
          } else {
            state.items.push({ ...item, quantity });
            state.totalPrice += item.price * quantity;
          }
        }
      }
    },
    removeItem: (state, action) => {
      const { item } = action.payload;
      const existingItem = state.items.find(
        (i) => i.id === item.id && i.sizeName === item.sizeName
      );
      if (existingItem) {
        if (existingItem.quantity > 1) {
          existingItem.quantity--;
        } else {
          state.items = state.items.filter(
            (i) => !(i.id === item.id && i.sizeName === item.sizeName)
          );
        }
        state.totalPrice -= item.price;
      } else {
        const index = state.items
          .map((i) => i.id === item.id)
          .lastIndexOf(true);
        if (index !== -1) {
          const existingItem = state.items[index];
          if (existingItem.quantity > 1) {
            existingItem.quantity--;
          } else {
            state.items.splice(index, 1);
          }
          state.totalPrice -= existingItem.price;
        }
      }
    },
    deleteItemsById: (state, action) => {
      const { item } = action.payload;
      state.items = state.items.filter(
        (i) => i.id !== item.id || i.sizeName !== item.sizeName
      );
      state.totalPrice = state.items.reduce(
        (total, i) => total + i.price * i.quantity,
        0
      );
    },
    clearCart: (state) => {
      state.items = [];
      state.totalPrice = 0;
    },
    setCafeName: (state, action) => {
      state.cafeName = action.payload;
    },
  },
});

export const { addItem, removeItem, deleteItemsById, clearCart, setCafeName } =
  cartSlice.actions;

export default cartSlice.reducer;
