import React from 'react';
import { useSelector } from 'react-redux';
import { ImPhone } from 'react-icons/im';

const Phone = () => {
  const { phone } = useSelector((s) => s.order);

  return (
    <div className="cart-detail">
      <div className="label">Номер телефона</div>
      <div className="cart-detail-input-data">
        <div className="cart-detail-input-data__icon">
          <ImPhone style={{ color: '#45a049' }} />
        </div>
        <input
          type="text"
          readOnly
          name="phone"
          id="phone"
          className="phone"
          value={phone}
          inputMode="numeric"
        />
      </div>
    </div>
  );
};

export default Phone;
