import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { useTelegram } from './hooks/useTelegram';
import { fetchCafes } from './redux/cafesSlice';

import Layout from './components/Layout';
import MainPage from './pages/MainPage';
import CafeMenuPage from './pages/CafeMenuPage';
import CartPage from './pages/CartPage';
import Loading from './components/Loading';
import LoadingError from './components/Loading/LoadingError';

function App() {
  const { tg } = useTelegram();
  const dispatch = useDispatch();

  const updateTheme = React.useCallback(() => {
    const localTimestamp = moment.tz('Europe/Moscow');
    const currentTime = localTimestamp.format('HH:mm');
    const timeBasedTheme =
      currentTime >= '19:00' || currentTime < '07:00' ? 'dark' : 'light';

    const { themeParams } = tg;
    const telegramTheme = themeParams && themeParams.theme;

    const finalTheme = telegramTheme || timeBasedTheme;

    document.documentElement.setAttribute('data-theme', finalTheme);

    if (finalTheme === 'dark') {
      tg.setHeaderColor('#1f1f1f');
      tg.setBackgroundColor('#1f1f1f');
      tg.setBottomBarColor('#1f1f1f');
    } else {
      tg.setHeaderColor('#ffffff');
      tg.setBackgroundColor('#ffffff');
      tg.setBottomBarColor('#ffffff');
    }
  }, [tg]);

  React.useEffect(() => {
    tg.ready();
    tg.expand();
    tg.disableVerticalSwipes();
    tg.enableClosingConfirmation();
    tg.MainButton.color = '#45a049';
    tg.MainButton.textColor = '#ffffff';

    updateTheme();

    tg.onEvent('theme_changed', updateTheme);

    return () => {
      tg.offEvent('theme_changed', updateTheme);
    };
  }, [tg, updateTheme]);

  const { status } = useSelector((state) => state.cafes);
  const cafes = useSelector((state) => state.cafes.cafes);

  React.useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCafes());
    }
  }, [status, dispatch]);

  if (status === 'loading' || status === 'idle') {
    return <Loading />;
  }
  if (status === 'failed') {
    return <LoadingError />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MainPage cafes={cafes} />} />
          <Route path="/cafe/:cafeId" element={<CafeMenuPage />} />
          <Route path="/cart" element={<CartPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
