import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { IoStarSharp } from 'react-icons/io5';

import './MainPage.scss';

const MainPage = ({ cafes }) => {
  const [cafeStatuses, setCafeStatuses] = React.useState({});

  React.useEffect(() => {
    const localTimestamp = moment.tz('Europe/Moscow');
    const currentTime = localTimestamp.format('HH:mm');

    const statuses = cafes.reduce((acc, cafe) => {
      const isOpen =
        currentTime >= cafe.opening_time && currentTime < cafe.closing_time;
      acc[cafe.id] = isOpen ? 'open' : 'open';
      return acc;
    }, {});

    setCafeStatuses(statuses);
  }, [cafes]);

  return (
    <div className="main-page">
      <div className="main-page__title">Выберите кафе</div>
      <div className="cafes-list">
        {cafes.map((cafe) => (
          <Link
            key={cafe.id}
            to={`/cafe/${cafe.id}`}
            className={`cafe-item ${
              cafeStatuses[cafe.id] === 'closed' ? 'disabled' : ''
            }`}
            style={{
              pointerEvents:
                cafeStatuses[cafe.id] === 'closed' ? 'none' : 'auto',
            }}
          >
            <div className="cafe-item__info">
              <div className="cafe-image-block">
                <img
                  src={`${cafe.image_url.String}`}
                  alt={cafe.name}
                  className="cafe-image"
                />
                <div className="cafe-image__time">
                  {cafe.opening_time} - {cafe.closing_time}
                </div>
              </div>
              <div className="cafe-item__info__desc">
                <div className="name_and_rating">
                  <div className="name">{cafe.name}</div>
                  <div className="rating">
                    <div className="rating__up">
                      <div className="rating__up__icon">
                        <IoStarSharp size={11} />
                      </div>
                      <div className="rating__up__count">
                        <div>{cafe.average_rating}</div>
                      </div>
                    </div>
                    <div className="rating__hr" />
                    <div className="rating__down">
                      <div className="">{cafe.number_of_reviews}</div>
                    </div>
                  </div>
                </div>
                <div className="phone_and_address">
                  <p>{cafe.phone}</p>
                  <p>{cafe.address}</p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MainPage;
