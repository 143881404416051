import { useTelegram } from './useTelegram';

export const useFocusHandlers = (setIsFocused) => {
  const { showMainButton } = useTelegram();

  const handleBlur = (event) => {
    const isSwitchingToAnotherInput =
      event.relatedTarget && event.relatedTarget.tagName === 'INPUT';

    if (!isSwitchingToAnotherInput) {
      setIsFocused(false);
      showMainButton(window.location.pathname);
    }
  };

  return { handleBlur };
};
