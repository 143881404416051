import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';

export const fetchCafeDetails = createAsyncThunk(
  'cafe/fetchCafeDetails',
  async (cafeId) => {
    const { data } = await axios.get(`/cafe?id=${cafeId}`);
    return data;
  }
);

const cafeSlice = createSlice({
  name: 'cafe',
  initialState: {
    cafe: {
      id: '',
      name: '',
      address: '',
      opening_time: '',
      closing_time: '',
      number_of_reviews: 0,
      average_rating: 0.0,
    },
    status: 'idle',
    error: null,
  },
  reducers: {
    clearCafeDetails(state) {
      state.cafe = {
        id: '',
        name: '',
        address: '',
        opening_time: '',
        closing_time: '',
        number_of_reviews: 0,
        average_rating: 0.0,
      };
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCafeDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCafeDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cafe = action.payload;
      })
      .addCase(fetchCafeDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { clearCafeDetails } = cafeSlice.actions;

export default cafeSlice.reducer;
