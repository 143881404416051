import React from 'react';

import './Navbar.scss';

const Navbar = ({ categories, onCategoryClick }) => {
  return (
    <nav className="navbar">
      <ul className="navbar-list">
        {categories.map((category) => (
          <li key={category.name} className="navbar-item">
            <button
              className="navbar-link"
              onClick={() => onCategoryClick(category.name)}
            >
              {category.name}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
