import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './Layout.scss';
import Header from '../Header';
import Loading from '../Loading';
import { clearCart } from '../../redux/cartSlice';
import { clearCafeDetails } from '../../redux/cafeSlice';
import { clearCafeMenu } from '../../redux/menuItemsSlice';
import { useTelegram } from '../../hooks/useTelegram';
import { setPhone } from '../../redux/orderSlice';
import LoadingError from '../Loading/LoadingError';

const Layout = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { status: categoriesStatus, error: categoriesError } = useSelector(
    (state) => state.categories
  );
  const { status: menuItemsStatus, error: menuItemsError } = useSelector(
    (state) => state.menuItems
  );

  const { tg, onClickMainButton, showMainButton } = useTelegram();
  const { cafes } = useSelector((state) => state.cafes);
  const { totalPrice } = useSelector((state) => state.cart);

  useEffect(() => {
    showMainButton(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, totalPrice]);

  React.useEffect(() => {
    const handleMainButtonClick = () => {
      onClickMainButton(navigate);
    };

    tg.MainButton.onClick(handleMainButtonClick);

    return () => {
      tg.MainButton.offClick(handleMainButtonClick);
    };
  }, [tg, onClickMainButton, navigate]);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const phoneNumber = params.get('phone');

    if (phoneNumber) {
      const formattedPhone = phoneNumber.replace(
        /^(\+7|7|8)?(\d{3})(\d{3})(\d{2})(\d{2})$/,
        '+7 ($2) $3-$4-$5'
      );
      dispatch(setPhone(formattedPhone));
    }

    if (pathname === '/') {
      dispatch(clearCafeDetails());
      dispatch(clearCafeMenu());
      dispatch(clearCart());
    }
  }, [dispatch, pathname]);
  if (categoriesStatus === 'failed' || menuItemsStatus === 'failed') {
    const errorMessage = categoriesError || menuItemsError;
    return <LoadingError errorMessage={errorMessage} />;
  }

  if (
    !cafes ||
    categoriesStatus === 'loading' ||
    menuItemsStatus === 'loading'
  ) {
    return <Loading />;
  }

  return (
    <main>
      <div className="wrapper">
        <Header />
        <Outlet />
      </div>
    </main>
  );
};

export default Layout;
