import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import './index.css';
import App from './App';

import cafesReducer from './redux/cafesSlice';
import cafeSlice from './redux/cafeSlice';
import menuReducer from './redux/menuItemsSlice';
import categoriesSlice from './redux/categoriesSlice';
import cartSlice from './redux/cartSlice';
import orderSlice from './redux/orderSlice';

const store = configureStore({
  reducer: {
    cafes: cafesReducer,
    cafe: cafeSlice,
    menuItems: menuReducer,
    categories: categoriesSlice,
    cart: cartSlice,
    order: orderSlice,
  },
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
