import React from 'react';
import { LuMinus, LuPlus } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import { MdDeleteSweep } from 'react-icons/md';

import './CartItem.scss';
import ItemModal from '../../../components/ItemModal';
import { addItem, removeItem, deleteItemsById } from '../../../redux/cartSlice';
import { useTelegram } from '../../../hooks/useTelegram';

const CartItem = ({ item, isFocused }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = React.useState(false);

  const { MainButton, showMainButton } = useTelegram();

  React.useEffect(() => {
    if (!isFocused) {
      if (isModalOpen) {
        MainButton.hide();
      } else {
        setTimeout(() => {
          showMainButton(window.location.pathname);
        }, 0);
      }
    }
  }, [MainButton, isModalOpen, showMainButton, isFocused]);

  const openModal = () => {
    setTimeout(() => {
      setModalOpen(true);
    }, 0);
  };
  const closeModal = () => setModalOpen(false);

  const handleAddItem = () => {
    dispatch(addItem({ item, quantity: 1 }));
  };
  const handleRemoveItem = () => {
    dispatch(removeItem({ item }));
  };

  const handleDeleteItem = () => {
    dispatch(deleteItemsById({ item }));
  };

  return (
    <>
      <div className="cart-item">
        <div className="item__photo" onClick={openModal}>
          <img src={`${item.image_url.String}`} alt="img" />
        </div>
        <div className="cart-item__details">
          <div className="cart-item-desc" onClick={openModal}>
            <p className="cart-item__name">
              {item.name} {item.sizeName}
            </p>
            <p className="cart-item__price">
              {item.price} <span className="rubl">₽</span>
            </p>
          </div>
          <div className="cart-item__right">
            <div className="cart-item-delete" onClick={handleDeleteItem}>
              <MdDeleteSweep style={{ color: '#e94c3797' }} />
            </div>
            <div className="cart-item__controls">
              <div onClick={handleRemoveItem} className="cart-item__button">
                <LuMinus />
              </div>
              <div className="cart-item__quantity">{item.quantity}</div>
              <div onClick={handleAddItem} className="cart-item__button">
                <LuPlus />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ItemModal open={isModalOpen} handleClose={closeModal} item={item} />
      )}
    </>
  );
};

export default CartItem;
