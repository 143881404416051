import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setComment } from '../../../redux/orderSlice';
import { TbMessageCircleFilled } from 'react-icons/tb';
import { useFocusHandlers } from '../../../hooks/useFocusHandlers';
import { useTelegram } from '../../../hooks/useTelegram';

const Comment = ({ setIsFocused, commentInputRef }) => {
  const dispatch = useDispatch();
  const { comment } = useSelector((s) => s.order);
  const { MainButton } = useTelegram();
  const [localComment, setLocalComment] = useState(comment);
  const { handleBlur } = useFocusHandlers(setIsFocused);

  const handleCommentChange = useCallback(
    (e) => {
      if (e.target.value.length <= 120) {
        setLocalComment(e.target.value);
        dispatch(setComment(e.target.value));
      }
    },
    [dispatch]
  );

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      document.activeElement.blur();
    }
  };

  const handleInputFocus = () => {
    commentInputRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  };

  return (
    <div className={'cart-detail'}>
      <div className="label">Комментарий к заказу</div>
      <div className="cart-detail-input-data">
        <div className="cart-detail-input-data__icon">
          <TbMessageCircleFilled style={{ color: '#45a049' }} />
        </div>
        <input
          value={localComment}
          onChange={handleCommentChange}
          placeholder="Введите комментарий"
          onFocus={() => {
            MainButton.hide();
            setIsFocused(true);
            handleInputFocus();
          }}
          onBlur={handleBlur}
          enterKeyHint="done"
          onKeyDown={handleKeyDown}
          ref={commentInputRef}
        ></input>
      </div>
    </div>
  );
};

export default Comment;
