import React from 'react';

import { IoClose } from 'react-icons/io5';

import './Search.scss';

const Search = ({ setActualItems, menuItems, searchTerm, setSearchTerm }) => {
  const searchRef = React.useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      document.activeElement.blur();
    }
  };

  const handleSearch = (event) => {
    const term = event ? event.target.value.toLowerCase() : searchTerm;
    setSearchTerm(term);
    const filteredItems = menuItems.filter((item) =>
      item.name.toLowerCase().includes(term)
    );
    setActualItems(filteredItems);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setActualItems(menuItems);
  };

  React.useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <div className="search">
      <input
        type="text"
        className="search__input"
        placeholder="Поиск..."
        value={searchTerm}
        maxLength={30}
        onChange={handleSearch}
        enterKeyHint="done"
        onKeyDown={handleKeyDown}
        ref={searchRef}
      />

      {searchTerm.length > 0 && (
        <div className="search__clear-icon" onClick={handleClearSearch}>
          <IoClose size={21} />
        </div>
      )}
    </div>
  );
};

export default Search;
