import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosArrowUp } from 'react-icons/io';

import './CafeMenuPage.scss';
import Navbar from '../../components/MenuComponents/Navbar/Navbar';
import FixedNavbar from '../../components/MenuComponents/FixedNavbar';
import Item from '../../components/Item';
import Search from '../../components/MenuComponents/Search';

import { fetchCafeDetails } from '../../redux/cafeSlice';
import { fetchCategories } from '../../redux/categoriesSlice';
import { fetchMenuItems } from '../../redux/menuItemsSlice';

const CafeMenuPage = () => {
  const { cafeId } = useParams();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categories);
  const { menuItems } = useSelector((state) => state.menuItems);

  const [searchTerm, setSearchTerm] = React.useState('');
  const [showScrollTop, setShowScrollTop] = React.useState(false);
  const [activeCategory, setActiveCategory] = React.useState(null);
  const [actualItems, setActualItems] = React.useState(menuItems);
  const { cafeName } = useSelector((s) => s.cart);

  React.useEffect(() => {
    if (!cafeName) {
      dispatch(fetchCafeDetails(cafeId));
      dispatch(fetchCategories(cafeId));
      dispatch(fetchMenuItems(cafeId));
    }
  }, [cafeName, dispatch, cafeId]);

  React.useEffect(() => {
    setActualItems(menuItems);
  }, [menuItems]);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 110) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }

      let active = null;
      categories.forEach((category) => {
        const element = document.getElementById(category.name);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 110 && rect.bottom >= 110) {
            active = category.name;
          }
        }
      });
      if (active) {
        setActiveCategory(active);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [categories]);

  const scrollToSection = (id) => {
    searchTerm && setSearchTerm('');
    setTimeout(() => {
      setActiveCategory(id);
      const element = document.getElementById(id);
      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: elementPosition - 70,
        });
      }
    }, 50);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  return (
    <div className="cat">
      <Search
        setActualItems={setActualItems}
        menuItems={menuItems}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <Navbar categories={categories} onCategoryClick={scrollToSection} />
      <FixedNavbar
        categories={categories}
        onCategoryClick={scrollToSection}
        activeCategory={activeCategory}
      />
      <div
        className={`fixed-upp-icon ${showScrollTop ? 'visible' : ''}`}
        onClick={scrollToTop}
      >
        <IoIosArrowUp
          size={35}
          color="white"
          style={{
            background: '#4caf50',
            padding: '5px',
            borderRadius: '50%',
          }}
        />
      </div>
      <div className="menu-items">
        {categories.map((category, id) => {
          const itemsInCategory = actualItems.filter(
            (item) => item.category === category.name
          );

          return itemsInCategory.length > 0 ? (
            <div key={id} className="main__category" id={category.name}>
              <div className="main__title">
                <p>{category.name}</p>
              </div>
              <div className="main__list">
                {itemsInCategory.map((item, id) => (
                  <Item key={id} {...item} />
                ))}
              </div>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default CafeMenuPage;
