import React, { useEffect, useState } from 'react';
import { Modal, Box, Slide } from '@mui/material';
import { useDispatch } from 'react-redux';

import './ItemModal.scss';
import { addItem } from '../../redux/cartSlice';
import { MdOutlineAdd, MdOutlineRemove } from 'react-icons/md';
import Sizes from './Sizes';

const style = {
  width: '100%',
  boxShadow: 24,
};

const ItemModal = ({ open, handleClose, item }) => {
  const [localItem, setLocalItem] = useState(item);
  const [selectedSize, setSelectedSize] = useState(null);

  useEffect(() => {
    if (item.sizes?.length > 0) {
      const defaultSize = item.sizes?.find((size) => size.is_default);
      if (defaultSize) setSelectedSize(defaultSize.id);
    }
  }, [item.sizes]);

  const dispatch = useDispatch();

  const [count, setCount] = useState(1);

  const countPlus = () => {
    if (count < 20) {
      setCount(count + 1);
    }
  };

  const countMinus = () => {
    if (count > 1) {
      setCount(count - 1);
    } else {
      handleClose();
    }
  };

  const handleAddToCart = () => {
    if (localItem.sizes) {
      const activeSize = item.sizes.find((size) => size.id === selectedSize);

      dispatch(
        addItem({
          item: { ...localItem, selectedSize: activeSize },
          quantity: count,
        })
      );
    } else {
      dispatch(addItem({ item, quantity: count }));
    }

    handleClose();
  };

  const [startY, setStartY] = useState(null);
  const [isSwiping, setIsSwiping] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
    setIsSwiping(true);
  };

  const handleTouchMove = (e) => {
    if (!isSwiping) return;

    const currentY = e.touches[0].clientY;
    const distance = currentY - startY;

    if (distance > 50) {
      setIsClosing(true);
      setTimeout(() => {
        handleClose();
        setIsClosing(false);
      }, 300);
    }
  };

  const handleTouchEnd = () => {
    setIsSwiping(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Slide
        direction="up"
        in={open && !isClosing}
        timeout={{ enter: 450, exit: 250 }}
        mountOnEnter
        unmountOnExit
      >
        <Box sx={style} className={`modal`}>
          <div className="modal-item__image__name">{item.name}</div>
          <div className="modal-item__image">
            <img src={`${item.image_url.String}`} alt={item.name} />
          </div>
          <Sizes
            item={localItem}
            setLocalItem={setLocalItem}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
          />
          <div className="hr"></div>
          <div className="modal-item__desc">
            {item.description && (
              <div className="desc">
                <div className="containts">Состав</div>
                <div className="item_desc">{item.description}</div>
              </div>
            )}
          </div>
          <div className="modal-item__card__btn">
            <div className="modal-item__card__btn__minus" onClick={countMinus}>
              <MdOutlineRemove size={25} />
            </div>
            <div
              className="modal-item__card__btn__text"
              onClick={handleAddToCart}
            >
              <p className="count">
                {count} / {count * localItem.price}{' '}
                <span className="rubl">₽</span>
              </p>
              <p className="add">Добавить</p>
            </div>
            <div className="modal-item__card__btn__plus" onClick={countPlus}>
              <MdOutlineAdd size={25} />
            </div>
          </div>
        </Box>
      </Slide>
    </Modal>
  );
};

export default ItemModal;
