import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiDeleteBinLine } from 'react-icons/ri';

import './CartPage.scss';
import CartItem from './CartItem/CartItem';
import Phone from '../../components/CartComponents/Phone';
import Address from '../../components/CartComponents/Address';
import Comment from '../../components/CartComponents/Comment';
import EmptyCart from '../../components/CartComponents/EmptyCart/EmptyCart';

import { clearCart } from '../../redux/cartSlice';

const CartPage = () => {
  const dispatch = useDispatch();
  const { items, totalPrice } = useSelector((s) => s.cart);
  const [isFocused, setIsFocused] = React.useState(false);
  const commentInputRef = React.useRef(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  return (
    <div className="cart-page">
      <div className="cart-page__title">
        <div className="cart-page__title__zakaz">Доставка</div>
      </div>

      <div className={'cart-details'}>
        <Phone />
        <Address
          setIsFocused={setIsFocused}
          commentInputRef={commentInputRef}
        />
        <Comment
          setIsFocused={setIsFocused}
          commentInputRef={commentInputRef}
        />
        {totalPrice > 0 && (
          <div className="total-price">Корзина — {totalPrice} ₽</div>
        )}
      </div>
      <div className="hr"></div>
      {totalPrice > 0 && (
        <div className="cart-page__title">
          <div className="cart-page__title__zakaz">Заказ</div>
          <div className="cart-clear" onClick={handleClearCart}>
            {items.length && <RiDeleteBinLine style={{ color: '#E94C37' }} />}
          </div>
        </div>
      )}

      <div className="cart-items">
        {items.length ? (
          items.map((item, id) => (
            <CartItem key={id} item={item} isFocused={isFocused} />
          ))
        ) : (
          <EmptyCart />
        )}
      </div>
    </div>
  );
};

export default CartPage;
